/**************** TABS *************************/
.tabs .nav{

    .nav-item{
        padding: 14px 26px;
        line-height: 1.79;
        color: $dark_text !important;
        margin-bottom: 0px;
        border: 0px;

        &:hover{
            border-bottom: 3px solid #1d82f585;
        }

        @include media-breakpoint-down(sm){
            padding: 10px 15px;
        }
    }

    .nav-link.active, .nav-item.show .nav-link{
        border-bottom: 3px solid #1D82F5;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
    }
}

.tab {
  overflow: hidden;
}

.tabcontent {
  display: none;
}



